*{
    scroll-behavior: smooth;
}
.home_banner{
    display: flex;
    height: 40vmax;
    position: relative;
}
.left_banner{
    padding-left: 6vmax;
    padding-right: 6vmax;
    text-align: center;
}
.main_head{
    color: #55A4ED;
    font-weight: 600;
    font-size: 4vmax;
    padding-top: 5vmax;
}
.sub_head{
    color: #55A4ED;
    font-weight: 600;
    font-size: 2.5vmax;
}
.banner_content{
    color: #6090BC;
    font-size: 1.5vmax;
}
.drop_style{
    background-color: rgba(0, 178, 255, 0.18);
    text-align: center;
    position: absolute;
    bottom: 0;
    width: 43.3vmax;
}
.home_drop_down{
    text-decoration: none;
    color: #55A4ED;
}
.homeimg_prop{
    height: 40vmax;
    float: right;
}
.scroll_down_button{
    position: relative;
    z-index: 2;
    top: -0.8vmax;
    display: flex;
    justify-content: center;
}
.proj_card{
    display: flex;
    padding-left: 10vmax;
    padding-right: 10vmax;
    padding-top: 2vmax;
    padding-bottom: 3vmax;
    align-items: center;
}
.left_proj{
    background-color: #00B2FF;
    flex: 0.3;
    height: 30vmax;
}
.right_proj{
    flex: 0.7;
    height: 25vmax;
    box-shadow: 0 0 0.8vmax 0 lightgray;
    display: flex;
    align-items: center;
}
.proj_img_prop{
    height: 20vmax;
    position: relative;
    left: -12vmax;
    box-shadow: 0px 0px 5px #00B2FF;
}
.proj_detail{
    position: relative;
    left: -5vmax;
}
.proj_head{
    float: left;
    color: #348FE2;
    font-size: 2vmax;
    font-weight: 600;
    padding-bottom: 1vmax;
}
.proj_des{
    float: right;
    font-size: 1.2vmax;
    line-height: 1.6;
}
.read_more_button{
    all: unset;
    color: white;
    background-color: #00B2FF;
    cursor: pointer;
    padding: 0.5vmax 1.5vmax 0.5vmax 1.5vmax;
    float: right;
    margin-top: 2vmax;
    margin-right: -5vmax;
}

@media(max-width: 780px){
    .right_banner{
        display: none;
    }
    .home_banner{
        background-image: url(../../assets/images/home_img.png);
        background-size: cover;
    }
    .banner_content{
        font-weight: 600;
    }
    .drop_style{
        display: none;
    }
}

@media(max-width: 615px){
    .proj_card{
        display: flex;
        flex-direction: column;
    }
    .left_proj{
        display: none;
    }
    .right_proj{
        flex-direction: column;
        text-align: center;
    }
    .proj_img_prop{
        left: 0;
        padding: 2.5vmax;
    }
    .proj_detail{
        left: 0;
        padding-left: 3vmax;
        padding-right: 3vmax;
    }
    .proj_head{
        float: none;
    }
    .proj_des{
        font-size: 1.5vmax;
    }
    .read_more_button{
        margin-right: -3vmax;
        margin-bottom: 1vmax;
    }
}
