.blogs {
    left: 5.5vmax;
}

.blog_card {
    display: flex;
}

.blog_image {
    z-index: 10;
    position: relative;
    left: 5vmax;
    top: 1vmax;
    width: 22vmax;
    height: 18vmax;
    object-fit: cover;
    filter: drop-shadow(3px 0px 15px rgba(0, 0, 0, 0.25));
}

.mobile_blog_img {
    display: none;
}

.blog_content {
    display: flex;
    justify-content: center;
    width: 85%;
    height: 20vmax;
    float: right;
    box-shadow: 0 0 0.8vmax lightgray;
    margin-bottom: 2.5vmax;
}

.blog_box {
    background-color: #348FE2;
    width: 4%;
}

.blog_post {
    width: 96%;
    padding: 1vmax;
    text-align: center;
}

.post {
    font-weight: 500;
    color: #0056A5;
    font-size: 1.7vmax;
    padding-bottom: 2px;
    margin-bottom: 1vmax;
    position: relative;
}
.post:before{
    content: "";
    position: absolute;
    width: 3%;
    height: 1px;
    bottom: 0;
    left: 48.5%;
    border-bottom: 2px solid #0056A5;
}

.blog_title {
    font-size: 1.4vmax;
    margin-bottom: 0.5vmax;
    letter-spacing: 1px;
    padding-left: 4vmax;
}

.blog_date {
    font-size: 1.3vmax;
    margin-bottom: 2vmax;
}

.blog_desc {
    padding-left: 4vmax;
    text-align: left;
    font-size: 1.1vmax;
}

.blog_link {
    all: unset;
    cursor: pointer;
    font-size: 2.5vmax;
}

.more_blogs {
    all: unset;
    background-color: #348FE2;
    color: white;
    float: right;
    cursor: pointer;
    padding: 0.5vmax 16vmax 0.5vmax 4vmax;
    line-height: 1.5;
    font-size: 1.5vmax;
}
.more_blogs:hover, .blog_link:hover {
    font-weight: 600;
}

@media(max-width: 600px){
    .blog_card{
        flex-direction: column;
        align-items: center;
        width: 100%;
    }
    .blog_image{
        display: none;
        left: 2vmax;
    }
    .mobile_blog_img {
        display: block;
    }
    .blog_box{
        display: none;
    }
    .blog_content{
        float: left;
        width: 96%;
        gap: 1rem;
    }
    .blog_date{
        margin-bottom: 0;
    }
}

@media(max-width: 550px){
    .post{
        display: none;
    }
}

@media(max-width: 500px){
    .blog_content{
        height: 40vmax;
        flex-direction: column;
        align-items: center;
    }
    .blog_image{
        left: 0;
    }
    .blog_desc{
        padding-left: 0;
    }
    .blog_title{
        padding-left: 0;
    }
}