.main_event_card{
    display: flex;
    justify-content: space-between;
    padding: 1vmax 0;
}


.left_event_card{
    /*background-color: blueviolet;*/
    width: 30%;
}
.bottom_img{
    width: 23vmax;
    height: 18.5vmax;
    background-color: white;
    box-shadow: 0 0 0.8vmax 0 lightgray;
    position: relative;
    margin-top: 0;
    margin-left: 0;
}
.middle_img{
    width: 23vmax;
    height: 18.5vmax;
    background-color: white;
    box-shadow: 0 0 0.8vmax 0 lightgray;
    position: relative;
    margin-top: -17vmax;
    margin-left: 1.5vmax;
}
.top_img{
    width: 23vmax;
    height: 18.5vmax;
    box-shadow: 0 0 0.8vmax 0 lightgray;
    position: relative;
    margin-top: -17vmax;
    margin-left: 3vmax;
}
.top_img>img{
    object-fit: cover;
    width: 23vmax;
    height: 18.5vmax;
}


.right_event_card{
    /*background-color: chartreuse;*/
    width: 65%;
    /*display: flex;
    flex-direction: column;*/
    box-shadow: 0 0 0.8vmax 0 lightgray;
}
.event_card_title{
    background-color: #348FE2;
    color: white;
    padding: 0.5vmax 2vmax;
    font-size: 1.3vmax;
}
.event_card_date{
    background-color: #00B2FF;
    color: white;
    padding: 0.5vmax 2vmax;
    font-size: 1.3vmax;
}
.event_card_des{
    color: #0E4170;
    height: 9.5vmax;
    padding: 2vmax 2vmax;
    font-size: 1.17vmax;
    display: flex;
    align-items: center;
}
.event_card_more{
    background-color: #00B2FF24;
    color: #0056A5;
    display: flex;
    justify-content: center;
    padding: 0.2vmax 2vmax;
    font-size: 2.5vmax;
    height: 2.5vmax;
}
.event_card_more>a{
    all: unset;
    display: flex;
    align-items: center;
}
.event_card_more>a:hover{
    cursor: pointer;
}

@media(max-width: 650px){
    .main_event_card .left_event_card{
        display: none;
    }
    .right_event_card{
        width: 100% !important;
    }
}