.search_prop{
    background-color: #00B2FF;
    padding-top: 1vmax;
    padding-bottom: 1vmax;
    display: flex;
    justify-content: center;
    font-size: 1.2vmax;
    margin-top: 3vmax;
}
.search_box{
    all: unset;
    border: 0.2vmax solid #0056A5;
    background-color: white;
    height: 3vmax;
    width: 45vmax;
    border-right: 0;
    padding-left: 1vmax;
    box-shadow: inset 0px 0px 20px rgba(0, 0, 0, 0.3);
}
.search_button{
    all: unset;
    height: 3vmax;
    background-color: #348FE2;
    color: white;
    padding-left: 0.6vmax;
    padding-right: 0.6vmax;
    border: 0.2vmax solid #0056A5;
    cursor: pointer;
    transition: 0.2s;
    display: flex;
    align-items: center;
}
.search_button:hover{
    filter: brightness(90%);
}
.search_button:active{
    filter: brightness(70%);
}
.search_button p{
    margin-left: 0.5vmax;
}
.filter_button{
    all: unset;
    height: 3vmax;
    background-color: #0056A5;
    color: white;
    padding-left: 1.5vmax;
    padding-right: 1.5vmax;
    border: 0.2vmax solid #0056A5;
    cursor: pointer;
    display: flex;
    align-items: center;
}
.search_divide{
    box-shadow: none;
    border: none;
    height: 0.5vmax;
    background-color: #0056A5;
}
