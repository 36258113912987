@font-face {
    font-family: 'Cerebri Sans Book';
    font-style: normal;
    font-weight: normal;
    src: url("./Cerebri\ Sans\ Book.woff");
}
.navbar{
    display: flex;
    background-color: white;
    align-items: center;
    justify-content: space-between;
    position: sticky;
    top: 0;
    z-index: 999;
    padding-top: 0.4vmax;
    padding-bottom: 0.4vmax;
    padding-left: 3vmax;
    box-shadow: 0 0.2vmax 0.2vmax lightgray;
    transition-delay: 0.15;
    max-width: 100vw;
}
.logo_prop{
    width: 5vmax;
    object-fit: contain;
    padding-right: 2vmax;
}
.right{
    width: 85vmax;
    background-color: #00B2FF;
    display: flex;
}
.mid-box_1{
    background-color: #D6F3FF;
    width: 1.5vmax;
}
.mid-box_2{
    background-color: #99E0FF;
    width: 1.5vmax;
}
.nav-heading > .option{
    text-decoration: none;
    list-style: none;
    padding: 1vmax 1.5vmax 1vmax 1.5vmax;
    color: white;
    font-family: 'Cerebri Sans Book';
    font-size: 1.3vmax;
}
.option > a{
    text-decoration: none;
    color: white;
    font-family: 'Cerebri Sans Book';
    font-size: 1.3vmax;
}
.nav-heading > .option:hover{
    background-color: #348FE2;
}
.nav-heading{
    display: flex;
}
.hamburger-menu{
    display: none;
}
.box{
    background-color: #00B2FF2E;
    height: 1vmax;
}

@media(max-width: 675px){
    .mid-box_2{
        display: none;
    }
    .right{
        display: none;
    }
    .mobile-right{
        display: flex;
        align-items: flex-start;
    }
    .nav-heading{
        display: flex;
        flex-direction: column;
        position: absolute;
        z-index: 990;
        background-color: #00B2FF;
        padding: 0;
        align-items: center;
        left: 0;
        width: 100%;
        top: 5vmax;
    }
    .nav-heading > li:hover{
        background-color: #00B2FF;
        font-weight: 600;
    }
    .nav-heading > li > a{
        font-size: 1.5vmax;
    }
    .hamburger-menu{
        display: flex;
        align-items: center;
        padding-right: 4vmax;
    }
    .hamburger-menu >a{
        text-decoration: none;
        color: #002D62;
    }
}