.resources {
    left: 10vmax;
}

.resources_link {
    display: flex;
    padding-left: 3vmax;
    flex-direction: column;
    gap: 0.5vmax;
}

.resources_link>a {
    text-decoration: none;
    color: black;
    font-size: 1.5vmax;
}

.resources_link>a:hover {
    font-weight: 600;
}