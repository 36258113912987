@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&display=swap');
.row2{
    display: flex;
    padding-top: 1vmax;
}
.col1{
    width:32%;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.col2{
    width:28%;
    border-left: solid 1px #FFFFFF;
    border-right: solid 1px white;
    text-align: left;
}
.col3{
    width:40%;
    height: 100%;
    text-align:left;
    padding-left:3%;
}
.container{
    background-color: #0056A5;
    font-family: 'Open Sans', sans-serif;
    color: white;
    height: auto;
}
.ASME{
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 3.5vmax;
    text-align: left;
    margin-left: 3.8vmax;
}
.sub{
    font-family: Open Sans;
    font-style: normal;
    font-weight: 400;
    font-size: 2vmax;
    margin-left: 3.8vmax;
}
.web{
    height : 3.5vmax;
    width: 3.5vmax;
}
.icon1{
    opacity: 0.8;
    font-size: 2.5vmax;
    color: white;
    padding-bottom: 2vmax;
}
.list>a{
    opacity: 0.8;
    color: white;
    text-decoration: none;
}

.icons4{
    margin-left: 3.8vmax;
    display: flex;
    gap: 2vmax;
}
.address{
    margin-left: 3.8vmax;
    font-size: 1.5vmax;
}
.list{
    list-style-type:none;
    padding-left: 0;
    margin-left: 3vmax;
    font-size: 1.5vmax;
    margin-top: 3vmax;
    }
.explore{
    margin-left: 3vmax;
    font-size: 2.5vmax;
    margin-top: 0.5vmax;
}
.list li{
    padding-top: 0.7vmax;
    padding-bottom: 0.7vmax;
}
.form{
    display: flex;
    flex-direction: column;
    height: 70%;
    gap: 1vmax;
    background-color: #0056A5;
    margin-top: 1.5vmax;
    width: 80%;
    
}
.form input{
    width: 28vmax;
    padding-left: 1.5vmax;
    padding-top: 1vmax;
    padding-bottom: 1vmax;
    background-color: #0056A5;
    border: 1px solid white;
    color: white;
    outline: none;
    font-size: 1.3vmax;
}
::placeholder{
    color: white;
    opacity: 0.5;
}
input :focus{
    border: 3px solid white;
}
.connect{
    text-align:"left";
    padding-left: 0;
    margin-top: 0;
    font-size: 3vmax;
}
#message{
    height: 5vmax;
}
#buttons {
    display: flex;
    flex-direction: row;
    width: 29vmax;
    gap: 3vmax;
}
#buttons input:hover{
    cursor: pointer;
    background-color: #fff;
    color: #0056A5;
    border-color: #0056A5;
}
.back_to_top{
    height: 3vmax;
    width: 3vmax;
    position: relative;
    left: 95%;
    margin-bottom: 1vmax;
    cursor: pointer;
}
.back_to_top:hover{
    opacity: 0.5;
}
.list li:hover{
    cursor: pointer;
    opacity: 0.6;
}
.icon1 :hover{
    cursor: pointer;
    opacity: 0.5;

}
@media (max-width: 550px){
    .row2{
        display: flex;
        flex-direction: column-reverse;
        height: auto;
        align-items: center;
        justify-content: center;        
    }
    .address {
        margin-left: 0;
    }
    .col2{
        display: none;
    }
    .col1{
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-evenly;
        gap: 1vmax;
        padding-top: 1vmax;
        padding-bottom: 1vmax;
    }
    .col3{
        width: 100%;
        margin: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 2vmax;
    }
    .icons4{
        margin-left: 0;
        justify-content: center;
    }
    .form{
        height:100%;
        display: flex;
        gap: 2vmax;
        align-items: center;
    }
    .list{
        margin-left: 0;
        margin-top: 0;
        display: flex;
        justify-content: space-evenly;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
    }
    .list li{
        padding-left: 10px;
        padding-right: 10px;
    }
    .back_to_top {
        left: 90%;
    }
}
