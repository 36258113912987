h1{
    font-size: 2.4vmax;
    font-weight: 600;
}
.about_style{
    padding-top: 1.5vmax;
    padding-left: 3vmax;
    padding-right: 3vmax;
    overflow: hidden;
}
::-webkit-scrollbar {
    width: 0;  /* Remove scrollbar space */
    background: transparent;  /* Optional: just make scrollbar invisible */
}
.gear_prop{
    height: 2.5vmax;
    position: relative;
    z-index: -1;
    top: -0.8vmax;
    left: 9vmax;
    padding-bottom: 0.6vmax;
}
.sample_prop{
    height: 28vmax;
    float: right;
    margin: 0 1vmax 1vmax 3vmax;
    box-shadow: 0 0 0.8vmax 0 lightgray;
}
h2{
    font-size: 1.6vmax;
    color: #348FE2;
    line-height: 3vmax;
}
.main_content{
    font-weight: 500;
    font-size: 1.3vmax;
    padding-bottom: 0.6vmax;
}
.content{
    font-weight: 400;
    font-size: 1.1vmax;
    padding-bottom: 1.2vmax;
}
.divider{
    width: 12vmax;
    margin-left: 0;
}
.first{
    padding-bottom: 3vmax;
}
.second{
    padding-bottom: 1.8vw;
}
@media(max-width: 560px){
    .sample_prop{
        display: none;
    }
}
