@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500&display=swap');
.memb_car_card{
    display: flex;
    align-items: center;
    min-width: 20vw;
    /* width: 40vw; */
    margin-right: 35px;
    position: relative;
    z-index: 10;
    flex-wrap: wrap;
    background-color: #fff;
    box-shadow: none;
    flex-direction: column;
    margin-bottom: 35px;
}
.card_fd_fix{
    flex-direction: row;
    /* width: 40vw; */
}
.memb_car_content{
    font-family: 'Montserrat', sans-serif;
    color: white;
    background-color: #00B2FF;
    height: 150px;
    width: 20vw;
    min-width: 220px;
    text-align: left;
    /* padding-left: 2px; */
    position: relative; 
    box-shadow: 0px 0px 30px #00000030;
}
.memb_car_content h2{
    color: white;
    font-weight: 700;
    padding-left: 20px;
    max-width: 210px;
}
.memb_car_content h3{
    font-weight: 400;
    padding-left: 20px;
    max-width: 210px;

}
.memb_car_image{
    display: flex;
    /* height: 210px; */
    width:20vw;
    min-width: 220px;
    flex-direction: column;
    justify-content: center;
    box-shadow: 0px 0px 30px #00000030;
}
.memb_car_card img{
    height: 100%;
    width: auto;
    object-fit: cover;
    /* transform-origin: 20%; */
    /* transform: translate(0,10px); */
    /* transform: rotate(45deg); */
}
.memb_car_image hr{
    border: none;
    margin: 0;
    background-color: #00B2FF;
    height: 5px;
}
.memb_car_icons_p{
    display: flex;
    flex-direction: column;
    position: absolute;
    right: -18px;
    height: 135px;
    justify-content: space-between;
    top: 50%;
    transform: translateY(-50%);
}
.memb_car_icons:hover{
    color: white;
    background-color: #00B2FF;
    border-color: white;
}
.memb_car_icons{
    border: 3.5px solid #00B2FF ;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background-color: white;
    color: #00B2FF;
    font-size: 24px;
    text-align: center;
    box-shadow: 0px 0px 4px #888888;
    cursor: pointer;
    

}
.memb_car_icons a{

    text-decoration: none;
    color: inherit;
   
}
.svg-inline--fa{
    padding-top: 5px;
}
@media(max-width:700px) {
    .memb_car_card{
        flex-direction: column !important;
        width: 100% !important;
        margin-left: 30px;
        
    }
    .memb_car_content h2{
        color: white;
        font-weight: 700;
        padding-left: 20px;
        font-size: 20px;
        padding-top: 10px;
        max-width: 80%;
    }
    .memb_car_content h3{
        font-size: 15px;
        padding-top: 4px;
        max-width: 80%;
    }
    
}






